@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

.layout-wrapper-fullscreen {
  margin: 0;
  padding: 0;
  .layout-menu-container,
  .layout-topbar,
  .layout-main,
  .layout-content,
  .layout-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  &.layout-static-inactive {
    margin-left: 0 !important;
  }
}
