.mobileToolbar {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.toolbar {
  background-color: #f8f9fa;
}

.largeButton {
  font-size: 1.4rem;
}

.panelTitle {
  margin: 1rem 0 1rem 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
  font-size: 1.25rem;
  background-color: white;
}
